<template>
   <div class="signCenter">
      <h4>报名须知</h4>
     <p>请期待</p>

   </div>
</template>

<script>
export default {
name: "sign"
}
</script>

<style lang="scss" scoped>
 .signCenter{
   text-align: center;
 }
</style>